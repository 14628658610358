<template>
    <div class="agendaroom-comment">
        <CompTable isSelector ref="comp_table" title="档案归集" :columns="columns" :tableApi="tableApi">
            <template #search="evt">
                <Input v-model.trim="evt.search.title" placeholder="议题名称" style="width:250px"></Input>
                <Select v-model="evt.search.issueType" filterable style="width:250px" transfer placeholder="议题类型">
                    <Option v-for="item in types" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
                </Select>
            </template>
        </CompTable>

        <Preview ref="preview"></Preview>
    </div>
</template>

<script>
import CompTable from "../jointly/components/comp-table"

import Route from "../communitymobilization/entity/Route"
import Request from "../jointly/utils/request"
import Utils from "../jointly/utils/utils"

export default {
    components: { CompTable },

    data() {
        return {
            tableApi: null,
            types: null,
            typesName: {},
            columns: [
                {
                    title: "议事主题",
                    key: "title",
                },
                {
                    title: "议题类型",
                    render: (h, params) => this.typesName[params.row.issueType] || "未知",
                },
                {
                    title: "归档时间",
                    render: (h, params) => this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd"),
                },
                {
                    title: "基层协商参与度",
                    width: 250,
                    render: (h, params) => {
                        return h(
                            "Select",
                            {
                                props: {
                                    value: params.row.participation,
                                },
                                style: {
                                    width: "200px",
                                },
                                on: {
                                    "on-change"(evt) {
                                        Request.post(
                                            "/gateway/syinfopublish/api/pc/chamber/updateIssue",
                                            {
                                                issueId: params.row.id,
                                                participation: evt,
                                            },
                                            {
                                                success: "修改成功",
                                                json: true,
                                            }
                                        )
                                    },
                                },
                            },
                            this.consultation?.map(v =>
                                h(
                                    "Option",
                                    {
                                        attrs: {
                                            value: v.dictKey,
                                        },
                                    },
                                    v.dictValue
                                )
                            )
                        )
                    },
                },
                {
                    float: "right",
                    title: "操作",
                    width: 180,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump("/agendaroomevolve", { id: params.row.id })
                                        },
                                    },
                                },
                                "查看"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDelete(params.row.id)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            // 协商参与度类型
            consultation: null,
        }
    },

    async created() {
        const res = await Request.get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
            dictType: "ISSUE_TYPE",
            appCode: "",
        })

        const typesName = {}

        Utils.each(res, v => {
            typesName[v.dictKey] = v.dictValue
        })

        this.typesName = typesName
        this.types = res

        this.tableApi = `/gateway/syinfopublish/api/app/chamber/listChamberIssueFlowByPage?orgCode=${parent.vue.loginInfo.userinfo.communityCode}&flowCode=done`
        // 获取协商参与度类型
        this.getConsultation()
    },

    methods: {
        getConsultation() {
            Request.get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "ISSUE_CONSULTATION",
                appCode: "",
            }).then(res => {
                this.consultation = res
            })
        },

        onDelete(id) {
            this.$Modal.confirm({
                title: "提示",
                content: "您正在删除数据，是否继续？",
                onOk: () => {
                    this.$get("/gateway/syinfopublish/api/pc/chamber/deleteIssueById", {
                        issueId: id,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            // 刷新表格数据
                            this.$refs.comp_table.refresh()
                        } else {
                            this.$Message.error({
                                content: res.desc || "删除失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
    },
}
</script>
<style lang="less">
.agendaroom-comment {
    .menu-bar-box {
        height: 45px;
        margin: 10px 10px 20px 10px;

        .menu-box {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #f3f3f3;
            background: #fff;
            border-radius: 5px;
            display: flex;

            .slider {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 50%;
                transition: all 0.3s ease;

                &::after {
                    content: "";
                    background: #2faaf7;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    bottom: 5px;
                    right: 5px;
                    border-radius: 3px;
                }
            }

            > .item-box {
                position: relative;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                padding: 0 20px;
            }

            > .activity {
                color: #fff;
            }
        }
    }
}
</style>
